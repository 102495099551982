import React from "react"
import { Link } from "gatsby"
import Header from '../components/header';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cta from '../components/cta';

const SecondPage = () => (
  <Layout>
    <SEO title="Wpółpraca" />
    <Header title="WSPÓŁPRACA"/>
    <section className="cooperation">
      <div className="container">
        <div className="row"></div>
      </div>
    </section>
    <Cta/>
  </Layout>
)

export default SecondPage